import { Box, Button, Card, CardContent, FormGroup, FormLabel } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import Input from '../../components/forms/Input';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import botService from '../../services/botService';

const BotChatboxSetting = () => {
  const botSelector = useSelector((state) => state.bot);
  const [bot, setBot] = useState(botSelector.currentBot);
  const [chatboxConfig, setChatboxConfig] = useState(
    JSON.parse(botSelector.currentBot.chatboxConfig) || {}
  );

  const initialValues = {
    primaryColor: chatboxConfig?.primaryColor || '',
    fontSize: chatboxConfig?.fontSize || '',
    chatboxWidth: chatboxConfig?.chatboxWidth || '',
    chatboxHeight: chatboxConfig?.chatboxHeight || '',
  };

  const handleSubmit = (values) => {
    let config = {};
    if (values?.primaryColor) {
      config.primaryColor = values.primaryColor.startsWith('#')
        ? values.primaryColor
        : `#${values.primaryColor}`;
    }
    if (values?.chatboxWidth) {
      config.chatboxWidth = `${values.chatboxWidth}px`;
    }
    if (values?.chatboxHeight) {
      config.chatboxHeight = `${values.chatboxHeight}px`;
    }
    if (values?.fontSize) {
      config.fontSize = `${values.fontSize}px`;
    }

    botService
      .updateChatboxConfig(bot.id, { config })
      .then((res) => {
        setChatboxConfig(res.config);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Card>
      <CardContent>
        <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={handleSubmit}>
          {({ handleChange, handleBlur, setFieldValue, values }) => (
            <Form>
              <FormGroup sx={{ mb: 3 }}>
                <FormLabel>Màu chính (HEX)</FormLabel>
                <Field
                  name="primaryColor"
                  as={Input}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="FF5722"
                  value={values.primaryColor}
                />
              </FormGroup>
              <FormGroup sx={{ mb: 3 }}>
                <FormLabel>Cỡ chữ (px)</FormLabel>
                <Field
                  as={Input}
                  name="fontSize"
                  placeholder="16"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.fontSize}
                />
              </FormGroup>
              <FormGroup sx={{ mb: 3 }}>
                <FormLabel>Chiều dài (px)</FormLabel>
                <Field
                  as={Input}
                  name="chatboxWidth"
                  placeholder="360"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.chatboxWidth}
                />
              </FormGroup>
              <FormGroup sx={{ mb: 3 }}>
                <FormLabel>Chiều rộng (px)</FormLabel>
                <Field
                  as={Input}
                  name="chatboxHeight"
                  placeholder="480"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.chatboxHeight}
                />
              </FormGroup>
              <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                <Button type="submit" variant="contained">
                  Lưu thông tin
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default BotChatboxSetting;
