import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userByAdmin from '../../services/admin/users/user';
import userService from '../../services/userService';

export const fetchUsers = createAsyncThunk('users/fetchUsers', async (params, thunkAPI) => {
  try {
    const response = await userByAdmin.getAllUser(params);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const deleteUser = createAsyncThunk('users/deleteUser', async (id, thunkAPI) => {
  try {
    await userService.deleteUser(id);
    return { id };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// Tạo action để tạo người dùng
export const createUser = createAsyncThunk('users/createUser', async (userData, thunkAPI) => {
  try {
    const response = await userService.createUser(userData); 
    console.log(response);
    return response; 
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const updateUser = createAsyncThunk('users/updateUser', async (userData, id,thunkAPI) => {
  try {
    console.log(userData);
    const response = await userService.updateInformation(userData); 
    return response; 
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// Slice cho user
const userSlice = createSlice({
  name: 'users',
  initialState: {
    users: [],
    currentPage: 1,
    pageSize: 5,
    total: 0,
    status: 'idle',
    error: null,
  },
  reducers: {
    setPage(state, action) {
      state.currentPage = action.payload;
    },
    setLimit(state, action) {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload.users || [];
        state.currentPage = action.payload.currentPage || 1;
        state.pageSize = action.payload.pageSize || 5;
        state.total = action.payload.total || 0;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.users = state.users.filter(user => user.id !== action.payload.id);
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.users.push(action.payload); 
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        const index = state.users.findIndex(user => user.id === action.payload.id);
        if (index !== -1) {
          state.users[index] = action.payload; 
        }
      });
  },
});

export const { setPage, setLimit } = userSlice.actions;
export default userSlice.reducer;
