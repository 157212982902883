import { useEffect, useState, useRef } from 'react';
import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
  Container,
  TextField,
  Button,
  Box,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import UserTableItem from './UserTableItem';
import { fetchUsers, deleteUser, setPage, setLimit } from '../../../reducers/slices/userSlice';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ModalCustom from '../../../components/modals/ModalCustom';
import CreateUser from './CreateUser';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const UserTable = () => {
  const dispatch = useDispatch();
  const { users, status, currentPage, pageSize, total } = useSelector((state) => state.users);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      const params = { page: 1, limit: pageSize, search: searchTerm };
      dispatch(fetchUsers(params));
    }, 500);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [dispatch, currentPage, pageSize, searchTerm]);

  const handleDeleteUser = async (userId) => {
    dispatch(deleteUser(userId));
  };

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage + 1));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setLimit(parseInt(event.target.value, 10)));
    dispatch(setPage(1));
  };

  const emptyRows = pageSize - Math.min(pageSize, total - (currentPage - 1) * pageSize);
  const handleClose = () => setIsOpen(false);
  const handleClick = () => {
    setIsOpen(true);
  };

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 2,
        }}
      >
        <TextField
          variant="outlined"
          placeholder="Tìm kiếm..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ marginRight: 2, width: { xs: '100%', sm: 400 } }}  // Responsive width
        />
        <Button
          onClick={handleClick}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            marginRight: 3,
            whiteSpace: 'nowrap',
          }}
          variant="contained"
          color="primary"
        >
          <PersonAddIcon />
          Thêm mới
        </Button>
      </Box>

      <TableContainer
        component={Paper}
        sx={{
          maxWidth: '100%',
          overflowX: 'auto',  // Kích hoạt cuộn ngang
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">STT</StyledTableCell>
              <StyledTableCell align="center">Tên khách hàng</StyledTableCell>
              <StyledTableCell align="center" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>Email</StyledTableCell>
              <StyledTableCell align="center" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>Số điện thoại</StyledTableCell>
              <StyledTableCell align="center">Địa chỉ</StyledTableCell>
              <StyledTableCell align="center">Chức năng</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {status === 'loading' ? (
              <>
                <TableCell colSpan={6} align="center">
                  <CircularProgress size={60} />
                </TableCell>
                {Array.from(new Array(pageSize)).map((_, index) => (
                  <TableRow style={{ height: 53 }} key={index}>
                    <TableCell colSpan={6} align="center"></TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              users?.map((user, index) => (
                <UserTableItem
                  key={user.id}
                  user={user}
                  index={index}
                  currentPage={currentPage - 1}
                  limit={pageSize}
                  onDelete={handleDeleteUser}
                />
              ))
            )}
            {emptyRows > 0 && status !== 'loading' && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={total}
        rowsPerPage={pageSize}
        page={currentPage - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ModalCustom open={isOpen} onClose={handleClose} size="large" placement="center">
        <CreateUser setIsOpen={setIsOpen} />
      </ModalCustom>
    </Container>
  );
};

export default UserTable;
