import { Box } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Sidebar from '../templates/components/sidebar/Sidebar';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';

const pages = [
  { title: 'Cài đặt bot', href: '/setting', icon: <SettingsOutlinedIcon /> },
  { title: 'Tích hợp bot', href: '/integration', icon: <IntegrationInstructionsOutlinedIcon /> },
  { title: 'Kho ảnh', href: '/images', icon: <AddPhotoAlternateOutlinedIcon /> },
  { title: 'Cấu hình chatbox', href: '/chatbox-setting', icon: <DisplaySettingsOutlinedIcon /> },
];

const Bot = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentBot } = useSelector((state) => state.bot);

  useEffect(() => {
    if (currentBot?.id) {
      gotoBot(currentBot.id);
    }
  }, [currentBot]);

  const gotoBot = (botId) => {
    const path = location.pathname;
    const subPath = path.split('/').slice(3).join('/');
    navigate(`${botId}/${subPath}`);
  };

  return (
    <Box display={'flex'} flexDirection={'row'}>
      <Sidebar pages={pages} />
      <Box px={5} py={4} flexGrow={1}>
        {currentBot && <Outlet />}
      </Box>
    </Box>
  );
};

export default Bot;
