import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import botReducer from './slices/botSlice';
import modalReducer from './slices/modalSlice';
import userReducer from './slices/userSlice';

const rootReducer = {
  auth: authReducer,
  bot: botReducer,
  modal: modalReducer,
  users: userReducer,
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
