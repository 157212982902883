import { Button } from '@mui/material';
import { useModal } from '../../../hooks/useModal';
import PackageCreate from './PackageCreate';

const Package = () => {
  const handleCloseModal = (data) => {
    console.log(data);
  };
  const packageModal = useModal('create-package', PackageCreate, {
    onClose: handleCloseModal,
  });

  const openModal = () => {
    packageModal.openModal();
  };
  return (
    <div>
      <h1>Package</h1>
      <Button onClick={openModal} variant="contained">
        Create new package
      </Button>
    </div>
  );
};

export default Package;
